@import 'styles/abstracts';

.overlay,
.box {
  width: 100%;
}

.overlay {
  background: rgba($black, 0.6);
  bottom: 0;
  display: flex;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-index-modal-box;

  @include screen-md {
    overflow-y: scroll;
    padding: 60px 0;
  }
}

.box {
  background: $white;
  height: 100%;
  outline: none;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  position: relative;

  @include screen-md {
    border-radius: 4px;
    height: auto;
    margin: auto;
    max-width: 600px;
    overflow-y: visible;
  }
}

.wrapper {
  @include screen-md {
    padding: 70px 30px 30px;
  }

  &:global(.has-no-padding-top) {
    @include screen-md {
      padding-top: 0;
    }

    .body {
      padding-top: 0;
    }
  }
}

.close {
  @include reset-button;

  cursor: pointer;
  left: 10px;
  line-height: 1;
  padding: 5px;
  position: absolute;
  top: 10px;

  @include screen-md {
    left: 25px;
    top: 25px;
  }
}

.corner {
  position: absolute;
  right: 15px;
  top: 15px;

  @include screen-md {
    right: 30px;
    top: 30px;
  }
}

.header {
  align-items: center;
  border-bottom: 1px solid $border-color-base;
  display: flex;
  justify-content: center;
  min-height: 60px;
  overflow: hidden;
  padding: 15px 59px;
  position: relative;
  z-index: 1000;

  @include screen-md {
    border: 0;
    display: block;
    margin-bottom: 10px;
    padding: 0;
    position: static;
  }
}

.back {
  @include reset-button;

  bottom: 0;
  left: 0;
  line-height: 1;
  padding: 0 20px;
  position: absolute;
  top: 0;
}

.headerCorner {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 59px;
}

@include overwrite {
  .title {
    @include t-section-title;

    margin: 0;

    @include max-screen-md {
      font-size: 20px;
    }
  }
}

.body {
  @include max-screen-md {
    padding: 50px 15px 120px;

    .header ~ & {
      padding-top: 15px;
    }
  }
}
