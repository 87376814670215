@import '../../styles/abstracts';

$border-width: 1px;

.wrapper {
  border-radius: $border-radius-base;
  display: flex;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 20px;
  padding: 10px $gap-small;

  * + & {
    margin-top: 20px;
  }
}

.info {
  background-color: rgba($info, 0.1);
  color: $info;
}

.success {
  background-color: rgba($success, 0.1);
  color: $success;
}

.warning {
  background-color: rgba($warning, 0.1);
  color: $warning;
}

.danger {
  background-color: rgba($danger, 0.1);
  color: $danger;
}

.icon {
  align-items: center;
  display: flex;
  font-size: 20px;
  margin-right: $gap-small;
}

.content {
  flex: 1 1 0;
}

.paragraph {
  margin: 0;

  & + & {
    margin-top: 5px;
  }
}
