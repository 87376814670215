.title {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1.3;
  margin: 0;
  text-transform: none;
}

.body {
  padding-bottom: 10px;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
