@import 'styles/abstracts';

.fieldsContainer {
  margin-bottom: 10px;
}

.reviewText {
  margin-bottom: 10px;
}

.label {
  font-size: 15px;
  font-weight: 600;
}

.actionContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;

  .actionButton {
    min-width: 100%;
    @include screen-md {
      min-width: 200px;
    }
  }
}

.detailsContainer {
  margin: 0 auto;
  max-width: 800px;
}
