@import 'styles/abstracts';
@import '../../styles/calendar-variables.module';

.container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  z-index: $date-range-z-index;

  @media screen and (max-width: 576px) {
    justify-content: space-between;
  }
}

.dateLabel {
  border: solid 1px $border-color-primary;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  min-width: 150px;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: center;

  p {
    margin: 0;
  }

  @media screen and (max-width: 576px) {
    font-size: 13px;
    min-width: 235px;
    padding: 8px 15px;
  }
}

.rowContainer {
  align-items: center;
  display: flex;
}

.arrow {
  cursor: pointer;
}

.datePicker {
  position: absolute;
  right: -10rem;
  top: 2rem;

  @media screen and (max-width: 576px) {
    right: -70%;
    top: 1rem;
  }
}

.pickerOpenContainer {
  position: relative;
}

.todayButton {
  background-color: $white;
  border: solid 1px $border-color-primary;
  border-radius: 4px;
  color: $green;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  padding: 10px 20px;
  position: relative;
  text-align: center;

  @media screen and (max-width: 576px) {
    margin: auto 8px;
    padding: 10px 20px;
  }
}
