.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.textBold {
  font-weight: 700;
}

.marginStart {
  margin-left: 5px;
}

.indent {
  margin-left: 20px;
}

.buttonLike {
  cursor: pointer;
}
