@import 'styles/abstracts';

.rightSidebarModal {
  // important here is to override classNames order in src/components/Modal/Modal.jsx and Modal.module.scss
  // once the order is changed, we can safely remove !important here
  max-width: unset !important;
  width: 100%;
  @include screen-md {
    transition: width $transition-base;
    // Same as above, we can remove !important once classNames order is changed
    width: 90% !important;
  }

  .buttonLink {
    cursor: pointer;
  }

  .actionContainer {
    display: flex;
    justify-content: flex-end;
    padding-top: 10px;

    .actionButton {
      min-width: 100%;
      @include screen-md {
        min-width: 200px;
      }
    }
  }

  .loadingContainer {
    align-items: center;
    color: $secondary;
    display: flex;
    height: 100%;
    justify-content: center;
  }
}

@media screen and (min-width: 1920px) {
  .rightSidebarModal {
    width: 65% !important;
  }

  .reviewMode {
    width: 860px !important;
  }
}
