@import 'styles/abstracts';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 13px;

  @include screen-md {
    font-size: 16px;
  }

  .fillParent {
    flex: 1;
  }

  .marginEnd {
    margin-right: 10px;
  }

  .finePrint {
    color: $gray-500;
    display: flex;
    flex: 1;
    font-size: 12px;
    justify-content: flex-end;
  }
}

.row {
  display: flex;
  gap: 20px;
}

.marginBottom {
  margin-bottom: 10px;
}

.column {
  flex: 1;
}

.card {
  border: 1px solid $gray-100;
  border-radius: 10px;
  padding: 10px;

  .cardTitle {
    font-weight: 700;
  }
}

.noMargin {
  margin-bottom: 0;
}

.alignEnd {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.alignCenter {
  align-items: center;
}

.input {
  max-width: 120px;
  min-height: 30px !important;
  padding: 5px 10px !important;
}

.marginStart {
  margin-left: 5px;
}

.currency {
  margin-left: 5px;
  text-wrap: nowrap;
}
