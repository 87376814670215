@import 'styles/abstracts';

.container {
  .firstLoader {
    width: 20%;
  }

  .firstRowContainer {
    padding: 15px;
  }

  .price {
    width: 80px;
  }

  .flexOne {
    flex: 1;
    padding-right: 15px;
  }

  .priceLabel {
    padding-right: 10px;
    width: 70%;
  }

  .loaderRow {
    display: flex;
    flex-direction: row;
    padding: 0 15px 15px;
  }

  .priceContainer {
    padding: 0 0 0 30px;
  }

  .short {
    width: 60%;
  }
}
