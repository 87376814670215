@mixin heading-props {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-base;
  line-height: $heading-line-height;
  text-transform: none;
}

@mixin t-main-title {
  @include heading-props;

  font-size: 36px;
}

@mixin t-section-title {
  @include heading-props;

  font-size: 30px;
}

@mixin t-subsection-title {
  @include heading-props;

  font-size: 22px;
  line-height: 1.4;
}

@mixin h-section-title {
  @include heading-props;

  font-size: 32px;
  font-weight: $font-weight-semi-bold;

  @include screen-md {
    font-size: 40px;
  }
}

@mixin h-section-subtitle {
  @include heading-props;

  font-size: 24px;
  font-weight: $font-weight-semi-bold;

  @include screen-md {
    font-size: 30px;
  }
}
