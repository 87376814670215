@import 'styles/abstracts';

.container {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-size: 13px;

  @include screen-md {
    font-size: 16px;
  }

  .fillParent {
    flex: 1;
  }
}

.row {
  display: flex;
  gap: 20px;
}

.marginBottom {
  margin-bottom: 10px;
}

.marginStart {
  margin-left: 5px;
}

.tooltipIcon {
  color: $secondary;
}

.column {
  flex: 1;
}

.noMargin {
  margin-bottom: 0;
}

.alignEnd {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
