@import 'styles/abstracts';

.errors {
  background-color: #f90;
  border-radius: 2px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-top: 0;
  padding: 6px 10px;
  text-align: center;
  width: 100%;
}

.marginBottom {
  margin-bottom: 10px;
}

.loadingContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.label {
  font-size: 15px;
  font-weight: 600;
}

.row {
  display: flex;
}

.firstSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @include screen-md {
    flex-direction: row;
  }
}

.column {
  flex: 1;
}

.gap {
  width: 20px;
}
