@import 'styles/abstracts';

.imgContainer {
  margin: 0;

  .img {
    height: 100px;
    object-fit: cover;
    width: 120px;
  }
}

.titleContainer {
  align-items: center;
  display: flex;
  padding-left: 20px;

  h3 {
    font-size: 16px;
    margin: 0;
  }
}

.card {
  display: flex;

  .marginStart {
    margin-left: 10px;
  }

  .link {
    color: $secondary;
  }

  .sup {
    margin-top: -5px;
  }
}
