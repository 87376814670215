@import 'styles/abstracts';

.row {
  display: flex;
  gap: 20px;
}

.marginBottom {
  margin-bottom: 10px;
}

.column {
  flex: 1;
}

.alignEnd {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}
