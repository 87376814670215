@import 'styles/abstracts';

.row {
  display: flex;
}

.column {
  flex: 1;
}

.title {
  font-weight: 700;
}

.rule {
  margin-top: 0;
}

.label {
  cursor: pointer;
}

.radio {
  appearance: none;
  background-color: $white;
  border: 1px solid $gray-100;
  border-color: $gray-700;
  border-radius: 50%;
  flex: 0 0 20px;
  height: 20px;
  margin-left: 0;
  margin-right: 10px !important;
  margin-top: 10px;
  margin-top: 5px !important; // overrides the default margin-top set in theme.css:6152
  transition: 0.2s ease-in-out;
  transition-property: background-color, border;
  width: 20px;
}
