@import 'styles/abstracts';

.input {
  background-color: $gray-100;
  border: 1px solid #e5e5e5;
  border: 1px solid $gray-100;
  border-radius: 2px;
  color: #666;
  max-width: 100%;
  min-height: 50px;
  padding: 10px 15px;
  width: 100%;
}

.textDanger {
  color: $danger;
  font-size: $font-size-base;
  margin: 0;
}

.hasError {
  border-color: $field-border-color-invalid;
}
