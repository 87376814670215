@import 'styles/abstracts';

.container {
  display: flex;
  gap: 20px;
  @include max-screen-md {
    flex-direction: column;
  }
}

.column {
  flex: 1;
}

.card {
  border: 1px solid $gray-100;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;

  .cardTitle {
    font-weight: 700;
  }
}
