.container {
  .checkboxSkeleton {
    height: 20px;
    margin-right: 20px;
    width: 20px;
  }
  
  .flex {
    display: flex;
  }
  
  .flexFill {
    flex: 1;
  }
  
  .paddingSmall {
    padding: 15px;
  }
  
  .paddingHorizontalSmall {
    padding: 0 15px;
  }
  
  .widthThird {
    width: 30%;
  }
  
  .marginBottomSmall {
    margin-bottom: 10px;
  }
  
  .widthSixty {
    width: 60%;
  }
  
  .alignCenter {
    align-items: center;
    display: flex;
  }
}
